<template>
  <div class="homePage">

    <HomeHeader :companyOption='companyOption' @changeCompany="changeCompany"></HomeHeader>
    <div class="contentArea">
      <div class="leftLayout">
        <div class="noticeList">
          <p class="title">待办通知</p>
          <div class="cells" :style="'height:'+noticeHeight+'px;'">

            <div class="cell" v-for="(item,index) in messageList" :key="index" @click="messageJump(item)">
              <div class="left">
                <img :src="item.iconUrl?item.iconUrl:defaultPng" alt="">
                <span>{{item.serviceName}}</span>
              </div>
              <span class="notice" v-if="item.msgValue">{{item.msgValue}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="rightLayout">
        <div class="topApp" ref="topApp">
          <p class="title">应用中心</p>
          <div class="appBox">
            <div class="oneApp" v-for="(item, index) in productsMenu" :key="index" @click="platformJump(item)">
              <img :src="item.iconUrl?item.iconUrl:defaultPng" alt="">
              <div>
                <p class="p1">{{ item.productName }}</p>
                <p class="p2">{{item.productDesc}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="dataArea">
          <el-tabs type="border-card">
            <el-tab-pane label="报货情况" :lazy="true"></el-tab-pane>
          </el-tabs>
          <div class="charts">
            <div id="secondChart" ref="secondChart" :style="'width: 100%; height: '+echartHeight+'px'"></div>
            <div id="thirdChart" ref="thirdChart" :style="'width: 100%; height: '+echartHeight+'px'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import newEcharts from 'echarts';
import HomeHeader from '@/components/homeHeader.vue';
import toOther from './mixins/toOther';
export default {
  name: 'marketingDigitalPlatform',
  components: {
    HomeHeader
  },
  data() {
    return {
      defaultPng: require('../../assets/personalPage/icon3.png'),
      currentTenantGuid: null,
      currentStaffGuid: null,
      companyOption: [],
      organisationName: '--',
      positionName: '--',
      messageList: [],
      productsMenu: [],
      dimen: 'order', //order=按报货 flow=按流向
      isSaleMan: 1, //业务员和其他为1，省总为0
      echartHeight: 0,
      noticeHeight: 0,
    };
  },
  mixins: [toOther],
  created() {
    document.title = '个人中心';
    localStorage.removeItem('loginType');
    this.getTenenants();
  },
  mounted() {
      this.noticeHeight = window.innerHeight - 170
  },
  methods: {
    //获取消息列表
    getMessageList() {
      var menuList = JSON.parse(localStorage.menuList);
      var tenantGuid = this.currentTenantGuid;
      var currentStaffGuid = this.currentStaffGuid;
      this.api.get('/ms-sales-perform-service/msg-group/get?menuGuid=' + menuList[0].guid + '&tenantGuid=' +
        tenantGuid + '&staffGuid=' + currentStaffGuid).then((res) => {
          if (res.data.code == '00000') {
            this.messageList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //切换公司
    changeCompany(guid) {
      this.getMenuList(guid);
    },
    getMenuList(tenantGuid) {
      let self = this;
      self.api
        .get('/ms-common-admin-platform-auth-service/menu/data/get-menu-by-currentuser?' + `appType=01&tenantGuid=` +
          tenantGuid + `&appSideIdentifier=QYD`)
        .then((res) => {
          if (res.data.code == '00000') {
            self.menuList = res.data.data;
            if (res.data.data[0].menuName == '主页') {
              this.getCurrentstaff(tenantGuid,res.data.data[0].path)
              // window.location = location.origin + res.data.data[0].path + "?username=" + self.$route.query
              //   .username +
              //   '&password=' + self.$route.query.password + '&currentTenantGuid=' + tenantGuid;
            }
          } else {
            self.$message.error(res.data.msg);
            self.loadingInstance.close();
          }
        });
    },
    getCurrentstaff(tenantGuid,path) {
      let self = this;
      self.api.get('/ms-personnel-service/staff/data/get-currentstaff?' + `tenantGuid=` + tenantGuid).then((res) => {
        if (res.data.code == '00000') {
          self.$store.commit('$_setUserId', res.data.data.userGuid);
          localStorage.setItem('userInfo', JSON.stringify(res.data.data));
           window.location.replace(location.origin + path + "?username=" + self.$route.query.username +'&password=' + self.$route.query.password + '&currentTenantGuid=' + tenantGuid);
        } else {
          self.$message.error(res.data.msg);
          self.loadingInstance.close();
        }
      });
    },
    //获取当前用户公司信息
    getTenenants() {
      var userGuid = localStorage.userId;
      this.api.get('/ms-personnel-service/staff-home/tenants?userGuid=' + userGuid).then((res) => {
        if (res.data.code == '00000') {
          this.companyOption = res.data.data.records;
          this.currentTenantGuid = this.$route.query.currentTenantGuid;
          this.companyOption.forEach((item) => {
            if (item.tenantGuid == this.currentTenantGuid) {
              this.currentStaffGuid = item.staffGuid;
            }
          });
          this.getMessageList();
          this.getOrgAndProduct();

        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取当前公司下的菜单
    getOrgAndProduct() {
      this.api
        .get(
          '/ms-personnel-service/staff-home/org-and-product?staffGuid=' + this.currentStaffGuid +
          '&tenantGuid=' + this.currentTenantGuid,
        )
        .then((res) => {
          if (res.data.code == '00000') {
            this.organisationName = res.data.data.organisationName;
            this.positionName = res.data.data.positionName;
            this.productsMenu = res.data.data.products;
            this.productsMenu.forEach(item => {
              if (item.icon) {
                let obj = JSON.parse(item.icon)
                this.$set(item, 'iconUrl', obj.path)
              }
            })
            //渲染应用产品后，渲染图表
            this.$nextTick(() => {
              let height = this.$refs.topApp.offsetHeight
              this.echartHeight = window.innerHeight - height - 190
              this.getTopTenData()
              this.getCustomerSalesData()
            })

          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //切换图标tab
    changeTab(e) {
      if (e.label == '报货情况') {
        this.getTopTenData() //近期销售额
        this.getCustomerSalesData() //近期销售额
      } else { }
    },

    //近期销售额(近6个月) 
    getTopTenData() {
      this.api.get('/ms-bizdata-statistics-service/personnel-report/recent-sale-trend?tenantGuid=' + this
        .currentTenantGuid + '&staffGuid=' + this.currentStaffGuid + '&dimen=' + this.dimen + '&isSaleMan=' + this
          .isSaleMan)
        .then((res) => {
          if (res.data.code == '00000') {
            this.initSecondChart(res.data.data)
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //本月、近3个月TOP6销售情况
    getCustomerSalesData() {
      this.api.get('/ms-bizdata-statistics-service/personnel-report/sale-trend?tenantGuid=' + this
        .currentTenantGuid + '&staffGuid=' + this.currentStaffGuid + '&dimen=' + this.dimen + '&isSaleMan=' + this
          .isSaleMan)
        .then((res) => {
          if (res.data.code == '00000') {
            this.initThirdChart(res.data.data)
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //初始化图表2
    initSecondChart(data) {
      let xData = data.lateralAxis;
      let salesData = [] //销售额
      let HBData = [] //环比
      let TBLastYear = [] //同比去年
      let TBQianNian = [] //同比前年
      data.datas.forEach(item => {
        if (item.name == '销售额') {
          salesData = item.values
        }
        if (item.name == '环比') {
          HBData = item.values
        }
        if (item.name == '同比去年') {
          TBLastYear = item.values
        }
        if (item.name == '同比前年') {
          TBQianNian = item.values
        }
      });
      let myChart = this.$echarts.init(document.getElementById('secondChart'));
      myChart.clear();
      let option = {
        title: {
          text: '近期销售额(近6个月)',
          left: 0,
          textStyle: {
            color: '#4D4D4D',
            fontWeight: '500',
            fontSize: 16,
          },
          top: 0,
        },
        grid: {
          containLabel: true,
          top: '80px',
          left: '10px',
          right: '10px',
          bottom: 0,
        },
        tooltip: {
          trigger: 'axis',
          extraCssText: 'text-align:left;',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999',
            },
          },
          formatter: function (params) {
            return (
              params[0].name +
              '<br>' +
              params[0].seriesName +
              '：' +
              params[0].data +
              '万元<br/>' +
              params[1].seriesName +
              '：' +
              params[1].data +
              '%<br/>' +
              params[2].seriesName +
              '：' +
              params[2].data +
              '%<br/>' +
              params[3].seriesName +
              '：' +
              params[3].data +
              '%'
            );
          },
        },
        toolbox: {
          feature: {
            dataView: {
              show: false,
              readOnly: false,
            },
            magicType: {
              show: false,
              type: ['line', 'bar'],
            },
            restore: {
              show: false,
            },
            saveAsImage: {
              show: false,
            },
          },
        },
        legend: {
          data: ['销售额', '环比', '同比去年', '同比前年'],
          right: '20px',
          selectedMode: false,
          top: '25px',
        },
        xAxis: [{
          type: 'category',
          data: xData,
          axisPointer: {
            type: 'shadow',
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
            },
          },
        },],
        yAxis: [{
          type: 'value',
          name: '单位：万元',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        {
          type: 'value',
          name: '单位：%',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        ],
        series: [{
          name: '销售额',
          barMaxWidth: '20px',
          type: 'bar',
          data: salesData,
          itemStyle: {
            normal: {
              color: '#A3D9D3',
            },
            borderRadius: [6, 6, 0, 0],
          },
        },
        {
          name: '环比',
          barMaxWidth: '20px',
          type: 'line',
          yAxisIndex: 1,
          smooth: true,
          data: HBData,
          itemStyle: {
            normal: {
              color: '#4f54c8',
            },
            borderRadius: [4, 4, 0, 0],
          },
          showSymbol: false,
          areaStyle: {
            opacity: 1,
            color: new newEcharts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(80, 85, 201, 0.2)',
            },
            {
              offset: 0.5,
              color: 'rgba(255, 255, 255, 0.1)',
            },
            ]),
          },
          emphasis: {
            focus: 'series',
          },
        },
        {
          name: '同比去年',
          type: 'line',
          data: TBLastYear,
          yAxisIndex: 1,
          smooth: true,
          itemStyle: {
            normal: {
              color: '#b47a9e',
            },
          },
        }, {
          name: '同比前年',
          type: 'line',
          data: TBQianNian,
          yAxisIndex: 1,
          smooth: true,
          itemStyle: {
            normal: {
              color: '#ebb762',
            },
          },
        },
        ],
      };
      myChart.setOption(option);
      window.addEventListener('resize', function () {
        myChart.resize();
      });
    },
    //初始化图表三
    initThirdChart(data) {
      let xData = [];
      let blockInCountData = [];
      let customerCountData = [];
      data.forEach((item) => {
        xData.push(item.goodsName);
        blockInCountData.push(item.amount);
        customerCountData.push(item.customers);
      });
      let myChart = this.$echarts.init(document.getElementById('thirdChart'));
      myChart.clear();
      let option = {
        title: {
          text: '本月品种TOP6销售情况',
          left: '10px',
          textStyle: {
            color: '#4D4D4D',
            fontWeight: '500',
            fontSize: 16,
          },
          top: 0,
          letf: 0,
        },
        grid: {
          containLabel: true,
          top: '60px',
          left: '30px',
          right: '30px',
          bottom: 0,
        },
        tooltip: {
          trigger: 'axis',
          extraCssText: 'text-align:left;',
          axisPointer: {
            type: 'shadow',
            crossStyle: {
              color: '#999',
            },
          },
          formatter: function (params) {
            return (
              params[0].name +
              '<br>' +
              params[0].seriesName +
              '：' +
              params[0].data +
              '万元<br/>' +
              params[1].seriesName +
              '：' +
              params[1].data +
              '家'
            );
          },
        },
        toolbox: {
          feature: {
            dataView: {
              show: false,
              readOnly: false,
            },
            magicType: {
              show: false,
              type: ['line', 'bar'],
            },
            restore: {
              show: false,
            },
            saveAsImage: {
              show: false,
            },
          },
        },
        legend: {
          data: ['销售额', '客户数'],
          right: '20px',
          top: 0,
        },
        xAxis: [{
          type: 'category',
          data: xData,
          axisPointer: {
            type: 'shadow',
          },
          axisLabel: {
            textStyle: {
              fontSize: 10,
              lineHeight: 14
            },
            color: '#5B5E63',
            formatter: function (params) {
              if (params != null) {
                var newParamsName = ""; // 最终拼接成的字符串
                var paramsNameNumber = params.length; // 实际标签的字数
                var provideNumber = 4; // 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整

                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ""; // 表示每一次截取的字符串
                    var start = p * provideNumber; // 开始截取的位置
                    var end = start + provideNumber; // 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p == rowNumber - 1) {
                      // 最后一次不换行
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      // 每一次拼接字符串并换行
                      tempStr = params.substring(start, end) + "\n";
                    }
                    newParamsName += tempStr; // 最终拼成的字符串
                  }
                } else {
                  // 将旧标签的值赋给新标签
                  newParamsName = params;
                }
                //将最终的字符串返回
                return newParamsName;
              }
              return params;
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
            },
          },
        },],
        yAxis: [{
          type: 'value',
          name: '单位：万元',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        {
          type: 'value',
          name: '单位：家',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        ],
        series: [{
          name: '销售额',
          barMaxWidth: '20px',
          type: 'bar',
          data: blockInCountData,
          itemStyle: {
            normal: {
              color: '#669CDB',
            },
            borderRadius: [6, 6, 0, 0],
          },
        },
        {
          name: '客户数',
          barMaxWidth: '20px',
          type: 'bar',
          yAxisIndex: 1,
          data: customerCountData,
          itemStyle: {
            normal: {
              color: '#A3D9D3',
            },
            borderRadius: [4, 4, 0, 0],
          },
        },
        ],
      };
      myChart.setOption(option);
      window.addEventListener('resize', function () {
        myChart.resize();
      });
    },


  },
};
</script>


<style scoped>
.topApp p,
.noticeList p{
  padding: 0;
  margin: 0;
}
  .homePage {
    background: #F6F7FA;
    min-height: 100vh;
    width: 100%;
    padding: 0.8rem 1rem 1rem 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .logoArea {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1.5rem;
  }

  .logoArea img {
    height: 2.125rem;
    margin-right: 1.875rem;
  }

  .logoArea span {
    font-size: 1.25rem;
    color: #323233;
  }

  .contentArea {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
  }

  /* 左侧 */
  .leftLayout {
    width: 22%;
    padding: 1.25rem 1.5rem;
    box-sizing: border-box;
    background: #fff;
  }

  /* .companyName {
    width: 70%;
    margin: 0 auto;
    font-size: 1.1rem;
    color: #4D4D4D;
    font-family: 'PingFang SC';
    margin-bottom: 0.375rem;
    font-weight: 600;
  } */
    .userInfo /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-size: 18px;
    color: #4D4D4D;
    font-family: 'PingFang SC';
    margin-bottom: 6px;
    font-weight: 500;
    /* width: 82%; */
  }

  .userInfo /deep/ .el-select-dropdown__item.selected {
    color: #4fa1a4;
  }

  .userInfo /deep/ .el-select .el-input .el-select__caret {
    height: auto;
    font-size: 1.1rem;
    color: #4D4D4D;
  }
  .userInfo {
    text-align: center;
    /* height: 40%; */
    padding-bottom: 14%;
  }

  .userInfo .userIcon {
    margin-top: 2rem;
    margin-bottom: 1.0625rem;
  }

  .userInfo .userIcon img {
    width: 6rem;
    height: 6rem;
    border: 0.75rem solid rgb(223, 225, 230, 0.2);
    border-radius: 50%;
    box-sizing: border-box;
  }

  .userInfo .position {
    color: #4D4D4D;
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .noticeList .title {
    margin-bottom: 1rem;
    padding-bottom: 16px;
    font-size: 1.1rem;
    color: #323233;
    border-bottom: 1px solid #DFE1E6;
    text-align: left;
    font-weight: 500;
  }

  .noticeList .cells {
    overflow-y: auto;
  }

  .noticeList .cell {
    padding: 0.75rem;
    box-sizing: border-box;
    height: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    color: #323233;
    border-bottom: 1px solid rgba(116, 116, 116, 0.06);
    cursor: pointer;
  }

  .noticeList .cell:hover {
    transition: all 0.5s;
    color: #4fa1a4;
  }

  .noticeList .cell .left {
    display: flex;
    align-items: center;
  }

  .noticeList .cell img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin-right: 1rem;
  }

  .noticeList .cell .notice {
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    text-align: center;
    background: #FF4831;
    border-radius: 50%;
    font-size: 0.625rem;
    color: #fff;
  }
  .noticeList .cell span{
    font-size: 1.1rem;
        word-break: keep-all;
    text-align: left;
  }

  /* 右侧 */
  .rightLayout {
    flex: 1;
    padding-left: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .topApp {
    background: #fff;
    padding: 1.25rem 1.5rem 0.25rem 1.5rem;
  }

  .topApp .title {
    font-size: 1.1rem;
    color: #323233;
    border-bottom: 1px solid #DFE1E6;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    text-align: left;
    font-weight: 500;
  }

  .topApp .appBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .topApp .oneApp {
    width: 23%;
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: left;
    background: #F6F7FA;
    margin-right: 2%;
    margin-bottom: 1rem;
    cursor: pointer;
    box-sizing: border-box;
  }

  .topApp .oneApp:hover {
    transition: all 0.5s;
    box-shadow: 0px 0 0.625rem 3px rgba(0, 0, 0, 0.1);
  }

  .topApp .oneApp img {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }

  .topApp .oneApp .p1 {
    font-size: 1.1rem;
    margin-bottom: 4px;
    font-weight:400;
  }

  .topApp .oneApp .p2 {
    font-size: 0.8rem;
    color: #6E6E6E;
  }

  .dataArea {
    margin-top: 1rem;
    flex: 1;
    width: 100%;
    background: #fff;
  }

  .dataArea .charts {
    padding: 0 1.5rem;
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }

  .dataArea /deep/ .el-tabs__item {
    width: 14.5rem;
    color: #323233;
    font-size: 1rem;
    height: 3rem;
    line-height: 3rem;
  }

  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
    color: #323233;
  }

  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    color: #323233;
    font-weight: 400;
    box-shadow: 2px 0 0.5rem 1px rgba(0, 0, 0, 0.1);
  }

  .dataArea /deep/ .el-tabs--border-card {
    border: none;
    box-shadow: 0 0 0 0;
  }

  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header,
  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item,
  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    border: none;
    font-size: 1.1rem;
  }
</style>
